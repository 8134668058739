import React from "react";
import './InnerPagination.scss';
import { isTablet, isTabletOnly, isMobile, isMobileOnly, isDesktop } from 'react-device-detect';
import Select from "react-select"
import { Next, Back, ArrowDownFlter } from '@components/icon/icon';

const InnerPagination = ({
    itemsPerPage,
    totalItems,
    currentPage,
    setCurrentPage,
    listingName
  }) => {
  
    // const {isMobile,isTabletOnly}=useDeviceMedia()
    const pageNumbers = []
    const pageNumberOptions = []
  
    // Generate page numbers
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i)
      pageNumberOptions.push({ value: i, label: i })
    }
  
    const totalPageNumbers = pageNumbers?.length
  
    const handlePageChange = value => {
      // handle change for dropdown select
      if (typeof value === "object") setCurrentPage(value.value)
      // handle change for number based page change
      else if (typeof value === "number") setCurrentPage(value)
      else
        switch (value) {
          case "back":
            setCurrentPage(page => --page)
            break
          case "next":
            setCurrentPage(page => ++page)
            break
          default:
            break
        }

        if(listingName==="Review"){
          typeof window!=="undefined"&&window.scrollTo({
            top: isMobile?1040:isTabletOnly?1180:1355,
            behavior: 'smooth',
          })
        }
        else if(listingName==="news"){
          const htmlBody = document.documentElement || document.body;
          htmlBody.scrollTop = 0;
        }
        else{
          const htmlBody1 = document.documentElement || document.body;
          htmlBody1.scrollTop = 220;
        }
    }

    const ArrowDownFlters = () => (
      <div className="down-indicator">
          <ArrowDownFlter />
      </div>
    )
  
    if(totalPageNumbers < 2) return null
  
    return (
        <div className="news-pagination-wrapper">
            <div className="menu-list">
                <div className="algolia-pagination align-items-center justify-content-between">
                    <section className="more-results-">
                        <div className="more-btn">
                            <button
                                className={`page-back text-20 bold more ${(currentPage <= 1) ? "disable" : "active"} `}
                                id="mynextbut"
                                onClick={() => handlePageChange("back")}
                                disabled={currentPage <= 1}
                            >
                               <Back /><span>Back</span>
                            </button>
                        </div>
                    </section>
                    <div className="al-pagination text-center text-20-16">
                        Page 
                        <Select
                            options={pageNumberOptions}
                            placeholder={(currentPage + 1)}
                            classNamePrefix={"property-dropdown"}
                            isSearchable={false}
                            onChange={handlePageChange}
                            styles={styles}
                            value={{ value: currentPage, label: currentPage }}
                            components={{ DropdownIndicator: () => <ArrowDownFlters /> }}
                        />
                        of {totalPageNumbers}
                    </div>
                    <section   className={`more-results-`}>
                        <button 
                            className={`page-next text-20 bold more ${(currentPage >= totalPageNumbers) ? "disable" : "active"} `}
                            id="mynextbut"
                            onClick={() => handlePageChange("next")}
                            disabled={currentPage >= totalPageNumbers}
                        >
                            <span>Next</span> <Next />
                        </button>
                    </section>
                </div>
            </div>
        </div>
    )
}

const styles = {
  control: styles => ({ 
      ...styles, 
      backgroundColor: null,
      paddingLeft: 0,
      border:0,
      minHeight: 26,

      borderRadius: 0,
      outline: 0,
      // fontSize: '1.8rem', //: '2.2rem',
      boxShadow: 'none',
      color: '#4A4A4A',
  }),
  valueContainer: (styles) => ({
      ...styles,
      // fontSize: () => isMobile ? '1.8rem' : '2.2rem',
      paddingLeft: 0
  }),
  dropdownIndicator: (styles) => ({
      ...styles,
      color: "#5D9272"
  }),
  indicatorsContainer: (styles) => ({
      ...styles,
      color: '#5D9272',
  }),
  indicatorSeparator: () => null,
  placeholder: (styles) => ({
      ...styles,
      marginLeft: 0
  })
}

export default InnerPagination